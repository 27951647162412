import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import beatbugs from '../../assets/images/beatbugs/new/beatbugs-banner.webp';
import beatbugsOne from '../../assets/images/beatbugs/new/beatbugs-1.webp';
import beatbugsTwoA from '../../assets/images/beatbugs/new/beatbugs-2-a.webp';
import beatbugsTwoB from '../../assets/images/beatbugs/new/beatbugs-2-b.webp';
import beatbugsTwoC from '../../assets/images/beatbugs/new/beatbugs-2-c.webp';
import beatbugsTwoD from '../../assets/images/beatbugs/new/beatbugs-2-d.webp';
import beatbugsThree from '../../assets/images/beatbugs/new/beatbugs-3.webp';
import beatbugsFour from '../../assets/images/beatbugs/new/beatbugs-4.webp';
import beatbugsFive from '../../assets/images/beatbugs/new/beatbugs-5.webp';
import beatbugsSix from '../../assets/images/beatbugs/new/beatbugs-6.webp';
import beatbugsClientImage from '../../assets/images/beatbugs/new/beatbugs-client-image.webp';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class BeatBugs extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Interactive Musical App for an Emmy Award-Winning Series
                </div>
                <div className="subtitle section-margin">
                  A sing-along, play-along musical experience that lets users take the best part of
                  the show with them
                </div>
                <div className="client-title section-margin">
                  CLIENT | <span style={{color: '#F1853D'}}>B</span>
                  <span style={{color: '#F3D012'}}>EAT</span>
                  <span style={{color: '#7CA6D6'}}> B</span>
                  <span style={{color: '#76C2CF'}}>UGS</span>
                </div>

                <div className="para-mini">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build an interactive sing-along, play-along musical app for Beat Bugs, a
                      popular children’s animated TV series inspired by the music of The Beatles.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed the native iOS and Android apps with over a 100 interactive game
                      elements and sounds and featuring four classic Beatles songs performed by the
                      Beat Bugs.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      The app garnered an impressive 4.5 average user rating and made it to number 5
                      in the Top Paid Apps in the Music category on the American App Store.
                    </p>
                  </div>
                </div>
                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/3ajH3yca0t4"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="text-with-image-container section-margin">
                    <div className="image-container text-center my-5">
                      <img src={beatbugsOne} alt="beatbugs" />
                    </div>
                    <div className="subheading-big">
                      Client&apos;s brief background and business problem
                    </div>
                    <p className="description">
                      Beat Bugs is a popular children’s animated TV series available on Netflix that is
                      inspired by the music of The Beatles. The series’ soundtrack contains 53
                      Beatles’ songs reimagined by some of today’s most famous artists who have lent
                      their voices to the project.
                    </p>
                    <br />
                    <p className="description">
                      The show reimagines these classic songs for a new generation – and teaches
                      some life lessons along the way. Each episode of this Emmy Award-winning
                      series springboards a classic Beatles song into a delightful backyard
                      experience filled with music, fun and adventure. Together with their friends
                      from the backyard, Beat Bugs explore, invent and sing.
                    </p>
                    <br />
                    <p className="description">
                      The client wanted to take the experience to a whole new level by debuting
                      their first official Beat Bugs interactive sing-along, play-along musical app.
                      They were looking for a development partner who could create the app’s art and
                      design to match their studio’s high quality and brand expectations.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Beat Bugs was our first major 3D project in the games space. It was entirely
                      created in Unity. We conducted an initial two-month discovery phase where our
                      engineers experimented with the Maya-Unity pipeline to create an interactive
                      demo scene that could be shared with the client.
                    </p>
                    <br />
                    <p className="description">
                      We followed that up with intensive R&D into the app’s key features such as
                      robust voice recording and real-time highlighting of lyrics. Once the
                      development work began, our team had to contend with several challenges
                      spanning areas of art, design and engineering.
                    </p>
                    <br />
                    <p className="description">
                      Our foremost design challenge was to convert the high poly models provided by
                      the client into the low poly models optimized for mobile. To accomplish this,
                      we reduced the poly count of characters just enough so as not to compromise
                      their appearance.
                    </p>
                    <br />
                    <p className="description">
                      Since the automated approaches failed to deliver acceptable quality, our team
                      undertook the intensive work of manually adjusting the poly count in each
                      object, gradually identifying the best practices for all the different shapes
                      and sizes.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={beatbugsTwoA}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">Low Poly</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={beatbugsTwoB}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">High Poly</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={beatbugsTwoC}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">Low Poly</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100 sub-pic"
                          src={beatbugsTwoD}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">High Poly</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Another challenge we overcame was the unstable recording and lyrics animation
                      implemented for the app’s karaoke feature. It took a month of rigorous testing
                      and several failed builds before we managed to build a shippable product with
                      both the features in robust condition. We achieved the required results by
                      making changes directly in XCode since Unity was failing to handle this to our
                      satisfaction.
                    </p>
                    <br />
                    <p className="description">
                      On the art front, we had to tweak the lighting in scenes many times. We had to
                      ensure the characters in these scenes were illuminated just enough to resemble
                      the show’s characters without overloading the processor.
                    </p>
                    <br />
                    <p className="description">
                      The Penny Lane scene is a good example. That scene was very light intensive
                      and took several iterations based on the client’s feedback before we were able
                      to finalize the look and bake the entire lighting system.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-11 mr-auto ml-auto">
                      <img className="w-100" src={beatbugsThree} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center ">
                    <div className="col-lg-11 mr-auto ml-auto">
                      <img className="w-100" src={beatbugsFour} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center ">
                    <div className="col-lg-11 mr-auto ml-auto">
                      <img className="w-100" src={beatbugsFive} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-lg-11 mr-auto ml-auto">
                      <img className="w-100" src={beatbugsSix} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      The Beat Bugs sing-along premium app was launched on both the Apple and Google
                      Play stores. The initial launch included four classic Beatles songs performed
                      by the Beat Bugs – All You Need Is Love, Ticket To Ride, Penny Lane, and
                      Yellow Submarine.
                    </p>
                    <br />
                    <p className="description">
                      The final shipped version featured 3D animated scenes and cascades of colorful
                      animations triggered by interactions with the environment and characters, with
                      over a 100 interactive elements and sounds used in the game.
                    </p>
                    <br />
                    <p className="description">
                      The Beat Bugs app was featured on the Apple Store in several countries upon
                      its release in early 2018. In the American App Store, it was featured under
                      the New Apps We Love section; in the Canadian App Store, it was featured in
                      the Recommended section.
                    </p>
                    <br />
                    <p className="description">
                      Within the American store, the app went as high as number 5 among Top Paid
                      Apps in the Music category and in the top 30 among all Kids apps within its
                      first week of launch.
                    </p>
                    <br />
                    <p className="description">
                      The application was subsequently launched on the Google Play Store as well.
                      Since its launch, it has maintained an impressive average user rating of 4.5.
                      Our team is looking to add additional levels to keep it fresh and engaging
                      over the course of the year.
                    </p>
                  </div>
                  <div className="col-lg-9 col-12 mr-auto ml-auto p-0 my-5 section-margin">
                    <img className="w-100" src={quotationBorder} alt="Quotation Border" />
                    <p className="review-body">
                      The apps received favorable media attention. The first was on an important
                      cable news show; Google and Apple featured the second one... I give them an
                      A-plus for project management and communication.
                    </p>
                    <div className="d-flex my-3">
                      <img src={beatbugsClientImage} alt="Shaan Kandawalla" />

                      <div className="p-3 m-0 mt-auto mb-auto">
                        <h2 className="review-name m-0" style={{fontWeight: 'bold'}}>
                          Shaan Kandawalla
                        </h2>
                        <p className="review-name mt-0">Cofounder, Playdate Digital</p>
                      </div>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default BeatBugs;
